import React from "react"
import { graphql } from "gatsby"
import { Flex, Box, Text, Icon } from "@chakra-ui/react"
import Markdown from "../../UI/Markdown"
import Wrapper from "../Wrapper"
import { IoArrowForward } from "react-icons/io5"

const Timeline = ({ data }) => {
  return (
    <Wrapper data={data.blockSettings} bg="sand">
      {data.title && (
        <Text as="h2" textAlign="center" mt="0">{data.title}</Text>
      )}
      {data.text && (
        <Box textAlign="center"><Markdown>{data.text}</Markdown></Box>
      )}
      <Flex layerStyle="grid" direction={["column","column","row"]} align="stretch" mt={16}>
      {data.dates.map((date, i) => (
        <Box key={i} layerStyle="gridItem" width={["100%","100%","calc(100%/3)"]} mt={i > 0 ? [8,8,0] : 0}>
          <Box
          bg="white"
          height="100%"
          position="relative"
          p={8}
          pt={10}>
            <Box
            pos="absolute"
            top="0"
            left={8}
            px={4}
            py={2}
            bg="wood"
            transform="translateY(-50%)">
              <Text color="white" fontWeight="bold">{date.dateLabel}</Text>
            </Box>
            <Markdown>{date.dateContent}</Markdown>
            {i > 0 && (
              <Box
              pos="absolute"
              top={[-4,-4,"50%"]}
              left={["50%","50%",-4]}
              bg="sand"
              borderRadius="50%"
              p={4}
              transform="translate(-50%,-50%)">
                <Icon
                color="wood"
                as={IoArrowForward}
                w={8}
                h={8}
                transform={["rotate(90deg)","rotate(90deg)","none"]} />
              </Box>
            )}
          </Box>
        </Box>
      ))}
      </Flex>
    </Wrapper>
  );
};

export default Timeline;

export const query = graphql`
  fragment TimelineBlock on MarkdownRemarkFrontmatterBlockscollection {
    title
    text
    dates {
      dateLabel
      dateContent
    }
  }
`;
