import React from "react"
import { graphql } from "gatsby"
import { SimpleGrid, Box, Text } from "@chakra-ui/react"
import Markdown from "../../UI/Markdown"
import CustomLink from "../../UI/CustomLink"
import CustomImage from "../../UI/CustomImage"
import Wrapper from "../Wrapper"

const ItemsList = ({ data }) => {
  const nbColumn = parseInt(data.nbColumn)
  return (
    <Wrapper data={data.blockSettings}>
      {data.title && (
        <Text as="h2" mt="0" maxW="1000px" mx="auto">{data.title}</Text>
      )}
      {data.text && (
        <Box maxW="1000px" mx="auto"><Markdown>{data.text}</Markdown></Box>
      )}
      <SimpleGrid columns={[1,2,3,nbColumn]} spacing={10} mt={10}>
      {data.items.map((item, i) => (
        <Box key={i}>
          <CustomLink href={item.href}>
            <CustomImage img={item.image} />
              {item.label && (
                <Box p={4} pb={0}>
                  <Text m="0" textAlign="center">{item.label}</Text>
                </Box>
              )}
          </CustomLink>
        </Box>
      ))}
      </SimpleGrid>
    </Wrapper>
  );
};

export default ItemsList;

export const query = graphql`
  fragment ItemsListBlock on MarkdownRemarkFrontmatterBlockscollection {
    title
    text
    nbColumn
    carousel
    items {
      image {
        publicURL
        childImageSharp {
          gatsbyImageData(
            width: 1000
          )
        }
      }
      label
      href
    }
  }
`;
