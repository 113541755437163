import React from "react"
import { graphql } from "gatsby"
import { Box, Text, Button } from "@chakra-ui/react"
import Markdown from "../../UI/Markdown"
import CustomLink from "../../UI/CustomLink"
import Wrapper from "../Wrapper"

const MiniBanner = ({ data }) => {
  return (
    <Wrapper data={data.blockSettings}>
      <Box bg="sand" position="relative" p={6} pb={10} maxW="600px" mx="auto" mb={5}>
        {data.title && (
            <Text as="h2" textAlign="center" mt="0">{data.title}</Text>
        )}
        {data.text && (
            <Box textAlign="center" mx="auto" maxW="450px"><Markdown>{data.text}</Markdown></Box>
        )}
        {data.cta && (
          <Box
          pos="absolute"
          top="100%"
          left="50%"
          transform="translate(-50%,-50%)"
          sx={{
            "a": {
              textDecoration: "none !important",
            }
          }}>
            <CustomLink href={data.cta.href}>
              <Button colorScheme="brand">{data.cta.label}</Button>
            </CustomLink>
          </Box>
        )}
      </Box>
    </Wrapper>
  );
};

export default MiniBanner;

export const query = graphql`
  fragment MiniBannerBlock on MarkdownRemarkFrontmatterBlockscollection {
    title
    text
    cta {
      label
      href
    }
  }
`;
