import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import Blocks from "../../components/Blocks"

const PageTemplate = ({data, pageContext}) => {
  const elements = data.markdownRemark.frontmatter
  return (
    <Layout title={elements.title} titleBG={elements.titleBG} slug={pageContext.slug} description={elements.description}>
      <Blocks blocks={elements.blockscollection} />
    </Layout>
  )
}

export const query = graphql`
  query PageQuery($id: String!) {
    markdownRemark( id: { eq: $id }) {
      html
      id
      frontmatter {
        slug
        title
        description
        titleBG {
          childImageSharp {
            gatsbyImageData(
              width: 1500
            )
          }
        }
        blockscollection {
          ...Blocks
        }
      }
    }
  }
`

export default PageTemplate