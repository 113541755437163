import React from "react"
import { graphql } from "gatsby"
import Markdown from "../../UI/Markdown"
import Wrapper from "../Wrapper"

const Markup = ({ data }) => {
  return (
    <Wrapper data={data.blockSettings}>
      <Markdown>{data.content}</Markdown>
    </Wrapper>
  );
};

export default Markup;

export const query = graphql`
  fragment MarkupBlock on MarkdownRemarkFrontmatterBlockscollection {
    content
  }
`;
