import React from "react"
import { graphql } from "gatsby"
import { Flex, Box, Text } from "@chakra-ui/react"
import Markdown from "../../UI/Markdown"
import Wrapper from "../Wrapper"

const Products = ({ data }) => {
  return (
    <Wrapper data={data.blockSettings}>
      {data.title && (
        <Text as="h2" textAlign="center" mt="0">{data.title}</Text>
      )}
      {data.text && (
        <Box textAlign="center"><Markdown>{data.text}</Markdown></Box>
      )}
      <Flex layerStyle="grid" justify="center" direction={["column","column","row"]} align="stretch">
      {data.products.map((product, i) => (
        <Flex direction="column" key={i} layerStyle="gridItem" width={["100%","100%","50%"]} mt={10}>
          <Text as="h3" color="chocolate" mt="0">{product.prodName}</Text>
          <Flex
          direction="column"
          height="100%"
          border="1px"
          borderColor="wood"
          sx={{
            ".label": {
              fontSize: "sm",
              mb: 0
            },
            ".price": {
              mb: 0
            }
          }}>
            {product.prodWhat && (
            <Box borderBottomWidth="1px" borderColor="wood" p={6}>
              <Text className="label" color="chocolate">Ce que vous verrez</Text>
              <Text>{product.prodWhat}</Text>
            </Box>
            )}
            {product.prodWho && (
            <Box p={6}>
              <Text className="label" color="chocolate">Pour qui ?</Text>
              <Text>{product.prodWho}</Text>
            </Box>
            )}
            {product.prodContent && (
            <Box p={6}>
              <Text className="label" color="chocolate">Contenu</Text>
              <Text>{product.prodContent}</Text>
            </Box>
            )}
            <Flex mt="auto">
            {product.prodTime && (
              <Box borderRightWidth="1px" borderColor="white" px={6} py={4} bg="chocolight" textAlign="center" width="50%">
                <Text className="label" color="white">Durée</Text>
                <Text>{product.prodTime}</Text>
              </Box>
              )}
              <Box borderLeftWidth={product.prodTime ? "1px" : 0} borderColor="white" px={6} py={4} bg="chocolight" textAlign={product.prodTime ? "center" : "left"} width={product.prodTime ? "50%" : "100%"}>
                <Text className="label" color="white">{product.prodPrice.label}</Text>
                <Flex align="center">
                  <Text className="price">{product.prodPrice.value}</Text>
                  <Text pl={3} fontSize="sm"><s>{product.prodPrice.valueOld}</s></Text>
                </Flex>
              </Box>
            </Flex>
          </Flex>
        </Flex>
      ))}
      </Flex>
      {data.conditions && (
        <Box textAlign="center" mt={6}>
          <Text fontStyle="italic">{data.conditions}</Text>
        </Box>
      )}      
    </Wrapper>
  );
};

export default Products;

export const query = graphql`
  fragment ProductsBlock on MarkdownRemarkFrontmatterBlockscollection {
    title
    text
    products {
      prodName
      prodWhat
      prodWho
      prodContent
      prodTime
      prodPrice {
        label
        value
        valueOld
      }
    }
    conditions
  }
`;
