import React from "react"
import { graphql } from "gatsby"
import Wrapper from "../Wrapper"
import { Flex, Box, Text, Button, Icon } from "@chakra-ui/react"
import Markdown from "../../UI/Markdown"
import CustomLink from "../../UI/CustomLink"
import CustomImage from "../../UI/CustomImage"
import { IoIosArrowDown } from "react-icons/io"

const HeroHeader = ({ data }) => {
  return (
    <Wrapper
    data={data.blockSettings}
    bg="sand"
    sx={{
      position: "relative",
      pb: 0,
      "&:before": {
        content: "''",
        display: "block",
        width: "100%",
        height: "85%",
        bg: ["chocolight","chocolight","white"],
        position: "absolute",
        top: 0,
        left: 0
      }
    }}>
      <Flex align={["flex-start","center"]} h="100%" direction={["column","row"]}>
        <Box
        bg="grayLight"
        sx={{
          position: "absolute",
          height: ["90%","90%","95%"],
          width: ["100%","100%","50%"],
          top: 0,
          right: 0,
          zIndex: "1"
        }}>
          <CustomImage
            width="100%"
            height="100%"
            img={data.image}
            alt="Emeline"
            sx={{
              ".gatsby-image-wrapper": {
                height: "100%"
              }
            }}
          />
        </Box>
        <Box
        pt={[6,10,20]}
        pb={[10,10,20]}
        pr={[6,10,10,20]}
        pl={[6,10,0]}
        width={["100%","100%","50%"]}
        bg="white"
        position={["absolute","absolute","relative"]}
        bottom={["5%","5%","inherit"]}
        left={["0","0","inherit"]}
        zIndex="2">
          <Text as="h1" mt="0">{data.title}</Text>
          {data.text && (
            <Markdown>{data.text}</Markdown>
          )}
          {data.signature && (
            <Text color="wood" mt={2}>{data.signature}</Text>
          )}
        </Box>
        {data.cta && (          
          <Box
          sx={{
            position: "absolute",
            top: ["95%","95%","85%"],
            left: "50%",
            transform: "translate(-50%,-50%)",
            zIndex: "3"
          }}>
            <CustomLink href={data.cta.href}>
              <Button colorScheme="brand" variant="round" aria-label="Voir la suite"><Icon as={IoIosArrowDown} w={8} h={8} /></Button>
            </CustomLink>
          </Box>
        )}
      </Flex>
    </Wrapper>
  );
};

export default HeroHeader;

export const query = graphql`
  fragment HeroHeaderBlock on MarkdownRemarkFrontmatterBlockscollection {
    title
    text
    signature
    cta {
      label
      href
    }
    image {
      publicURL
      childImageSharp {
        gatsbyImageData(
          width: 1000
        )
      }
    }
  }
`;
