import React from "react"
import { graphql } from "gatsby"
import { Flex, Box, Text } from "@chakra-ui/react"
import Markdown from "../../UI/Markdown"
import Wrapper from "../Wrapper"

const RentalPrices = ({ data }) => {
  return (
    <Wrapper data={data.blockSettings}>
      {data.title && (
        <Text as="h2" textAlign="center" mt="0">{data.title}</Text>
      )}
      {data.text && (
        <Box textAlign="center"><Markdown>{data.text}</Markdown></Box>
      )}
      <Box
      height="100%"
      border="1px"
      borderColor="wood"
      mx="auto"
      maxW="450px"
      sx={{
        "& > div:not(:last-child)": {
          border: "1px",
          borderWidth: "0 0 1px 0",
          borderColor: "wood"
        }
      }}>        
        {data.rentalItems.map((item, i) => (
        <Flex key={i}>
          <Box p={6} width="80%" borderRight="1px" borderRightColor="wood">
            {item.label && (
              <Text fontWeight="bold">{item.label}</Text>
            )}
            {item.terms && (
              <Text>{item.terms}</Text>
            )}
          </Box>
          <Flex align="center" justify="center" p={6} width="20%">{item.price}</Flex>
        </Flex>
      ))}
      </Box>
    </Wrapper>
  );
};

export default RentalPrices;

export const query = graphql`
  fragment RentalPricesBlock on MarkdownRemarkFrontmatterBlockscollection {
    title
    text
    rentalItems {
      label
      terms
      price
    }
  }
`;
