import React from "react"
import { graphql } from "gatsby"
import { Flex, Box, Text, Icon } from "@chakra-ui/react"
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import Wrapper from "../Wrapper"
import Markdown from "../../UI/Markdown"
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io"

import 'swiper/css'
import 'swiper/css/pagination'

const Testimonial = ({ data }) => {
  const prevRef = React.useRef(null)
  const nextRef = React.useRef(null)
  return (
    <Wrapper
    data={data.blockSettings}
    sx={{
      ".chakra-container": {
        px: 0
      }
    }}>
      {data.title && (
        <Text as="h2" textAlign="center" mt="0">{data.title}</Text>
      )}
      {data.text && (
        <Box textAlign="center"><Markdown>{data.text}</Markdown></Box>
      )}
      <Box
      mt={10}
      sx={{
        ".swiper": {
          display: "flex",
          flexDirection: "column-reverse",
          px: [8,20]
        },
        ".swiper-wrapper": {
          alignItems: "stretch"
        },
        ".swiper-slide": {
          h: "auto",
          opacity: "0",
          transition: "opacity 100ms linear",
          "&.swiper-slide-active": {
            opacity: "1"
          },
          "&:hover": {
            cursor: "grab"
          }
        },
        ".swiper--nav": {
          pos: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: "10",
          bg: "white",
          borderRadius: "50%",
          cursor: "pointer",
          mt: "-40px",
          "&.swiper--prev": {
            left: [2,4,4,"calc(50% - 450px)"]
          },
          "&.swiper--next": {
            right: [2,4,4,"calc(50% - 450px)"]
          }
        },
        ".swiper-pagination": {
          position: "initial",
          mt: 6,
          ".swiper-pagination-bullet": {
            borderColor: "wood",
            borderWidth: "2px",
            borderStyle: "solid",
            bg: "white",
            opacity: "1",
            "&:hover:not(.swiper-pagination-bullet-active)": {
              borderColor: "chocolate"
            },
            "&.swiper-pagination-bullet-active": {
              bg: "wood"
            }
          }
        }
      }}>
        <Swiper
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
        navigation
        pagination={{ clickable: true }}
        modules={[Navigation, Pagination]}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}>
          {data.items.map((item, i) => (
            <SwiperSlide key={i}>
              <Flex bg="white" h="100%" direction="column" justify="center">
                <Flex
                direction="column"
                justify="center"
                height="100%"
                bg="chocolight"
                maxW="700px"
                px={[12,16]}
                py={8}
                mb={5}
                mx="auto"
                pos="relative"
                sx={{
                  "&:after": {
                    content: "''",
                    display: "block",
                    width: 0,
                    height: 0,
                    bg: "transparent",
                    borderStyle : "solid",
                    borderTopColor : "chocolight",
                    borderBottomColor : "transparent",
                    borderLeftColor : "transparent",
                    borderRightColor : "transparent",
                    borderWidth : "15px 20px 0 0",
                    pos: "absolute",
                    top: "100%",
                    left: "50%",
                    transform: "translateX(-50%)"
                  },
                  ".quote": {
                    pos: "absolute",
                    "&:first-of-type": {
                      top: 8,
                      right: "100%",
                      mr: ["-35px","-40px"],
                      lineHeight: "1rem"
                    },
                    "&:last-of-type": {
                      bottom: 8,
                      left: "100%",
                      ml: ["-35px","-40px"],
                      lineHeight: "2rem"
                    }
                  }
                }}>
                  <Box color="chocolate" fontSize={["2rem","3rem"]} className="quote">«</Box>
                  <Markdown>{item.testimonial}</Markdown>
                  <Box color="chocolate" fontSize={["2rem","3rem"]} className="quote">»</Box>
                </Flex>
                <Text textAlign="center" fontWeight="bold">{item.author}</Text>
              </Flex>
            </SwiperSlide>
          ))}
          <Box color="wood" ref={prevRef} className="swiper--nav swiper--prev"><Icon as={IoIosArrowDropleft} w={10} h={10} /></Box>
          <Box color="wood" ref={nextRef} className="swiper--nav swiper--next"><Icon as={IoIosArrowDropright} w={10} h={10} /></Box>
        </Swiper>
      </Box>
    </Wrapper>
  );
};

export default Testimonial;

export const query = graphql`
  fragment TestimonialBlock on MarkdownRemarkFrontmatterBlockscollection {
    title
    text
    items {
      author
      testimonial
    }
  }
`;
