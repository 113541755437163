import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { SimpleGrid, Box, Text } from "@chakra-ui/react"
import CustomImage from "../../UI/CustomImage"

const RentalItems = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(filter: {frontmatter: {contentType: {eq: "location"}}}) {
          group(field: frontmatter___type) {
            fieldValue
            totalCount
            edges {
              node {
                frontmatter {
                  contentType
                  picture {
                    childImageSharp {
                      gatsbyImageData(
                        width: 450
                      )
                    }
                  }
                  title
                  brand
                  type
                  description
                }
              }
            }
          }
        }
      }
    `
  )
  // console.log(allMarkdownRemark.group)
  return (
    <Box>
      {allMarkdownRemark.group.map(( { fieldValue, edges } ) => {
        return (
          <Box mb={16} key={fieldValue}>
            <Text as="h2" mt={0}>{fieldValue}</Text>
            <SimpleGrid columns={[1,2,3,4]} spacing={10} mb={10}>
              {edges.map(( { node, index } ) => {
                return (
                  <Box key={index}>
                    <Box
                    pos="relative"
                    sx={{
                      overflow: "hidden",
                      "&:after": {
                        content: "''",
                        display: "block",
                        pb: "100%",
                        bg: "grayDark"
                      },
                      "& > div": {
                        pos: "absolute",
                        width: "100%",
                        left: 0,
                        top: "50%",
                        transform: "translateY(-50%)"
                      }
                    }}>
                      <CustomImage img={node.frontmatter.picture} />
                    </Box>
                    <Text as="h3" fontFamily="body" fontSize="xl" mt={5}>{node.frontmatter.title}</Text>
                    <Text>{node.frontmatter.description}</Text>
                  </Box>
                )    
              })}
            </SimpleGrid>
          </Box>
        )    
      })}
    </Box>
  );
};

export default RentalItems;
