import React from "react"
import CustomContainer from "../UI/CustomContainer"
import { Center, Text } from "@chakra-ui/react"
import colors from "../../theme/colors"


const Wrapper = ({data, children, sx, ...props}) => {
  const blockTitle = data.showTitle && data.blockname ? data.blockname : false;
  const blockID = data.id;
  const textAlign = data.textAlign ? data.textAlign : false;
  const bgColor = data.bgColor ? data.bgColor : false;
  const fluidContainer = data.containerFluid;
  const containerMax = data.containerMax;
  const fullHeight = data.fullHeight;
  const pTop = data.verticalPadding ? data.verticalPadding.top ? data.verticalPadding.top : false : false;
  const pBottom = data.verticalPadding ? data.verticalPadding.bottom ? data.verticalPadding.bottom : false : false;
  const bgImgUrl = data.bgImg ? data.bgImg.childImageSharp.fluid.src : "";

  const wrapperProps = {};
  const containerProps = {};

  if (blockID) {
    wrapperProps.id = blockID;
  }
  if (textAlign) {
    wrapperProps.textAlign = textAlign
  }
  if (fullHeight) {
    wrapperProps.minHeight = "100vh";
  }
  if (bgColor) {
    wrapperProps.bg = bgColor;
  }
  sx = sx || {};
  wrapperProps.sx = sx;
  if (textAlign && textAlign === "center") {
    wrapperProps.sx = {
      "img": {
        mx: "auto"
      },
      ...sx
    }
  }
  if (bgImgUrl) {
    wrapperProps.sx.backgroundColor = colors.primary
    wrapperProps.sx.background = "url('" + bgImgUrl + "'), linear-gradient(rgba(0,0,0,.4),rgba(0,0,0,.4))"
    wrapperProps.sx.backgroundBlendMode = "multiply"
    wrapperProps.sx.backgroundSize = "cover"
    wrapperProps.sx.backgroundPosition = "center"
    wrapperProps.sx.backgroundRepeat = "no-repeat"
    wrapperProps.sx.position = "relative"
  }

  return (
    <Center
    className="block-wrapper"
    pt={pTop ? pTop : [16,20,24]}
    pb={pBottom ? pBottom : [16,20,24]}
    {...wrapperProps}
    {...props}>
      <CustomContainer fluid={fluidContainer} containerMax={containerMax} {...containerProps}>
        {blockTitle !== false && (
          <Text as="h2">
            {blockTitle}
          </Text>
        )}
        {children}
      </CustomContainer>
    </Center>
  );
};

export default Wrapper;
