import React from "react"
import { graphql } from "gatsby"
import Markup from "./Markup"
import HeroHeader from "./HeroHeader"
import Cards from "./Cards"
import Banner from "./Banner"
import Testimonial from "./Testimonial"
import ImgMarkupTwoCol from "./ImgMarkupTwoCol"
import MiniBanner from "./MiniBanner"
import Timeline from "./Timeline"
import Products from "./Products"
import Contact from "./Contact"
import ItemsList from "./ItemsList"
import Rental from "./Rental"
import RentalPrices from "./RentalPrices"

const blocksComponents = {
  Markup: Markup,
  HeroHeader: HeroHeader,
  Cards: Cards,
  Banner: Banner,
  Testimonial: Testimonial,
  ImgMarkupTwoCol: ImgMarkupTwoCol,
  MiniBanner: MiniBanner,
  Timeline: Timeline,
  Products: Products,
  Contact: Contact,
  ItemsList: ItemsList,
  Rental: Rental,
  RentalPrices: RentalPrices
};

const Block = ({ block }) => {
  const BlockComp = blocksComponents[block.type];
  if (BlockComp) {
    return <BlockComp data={block} />;
  }
  return <> </>;
};

export default ({ blocks }) => {
  blocks = blocks || [];
  return blocks.map((blockItem, i) => <Block block={blockItem} key={i} />);
};

export const query = graphql`
  fragment Blocks on MarkdownRemarkFrontmatterBlockscollection {
    type
    blockSettings {
      showTitle
      blockname
      id
      textAlign
      containerMax
      containerFluid
      fullHeight      
      verticalPadding {
        top
        bottom
      }
      bgImg {
        childImageSharp {
          fluid(maxWidth: 1500){
            src
          }
        }
      }
    }
    ...MarkupBlock
    ...HeroHeaderBlock
    ...CardsBlock
    ...BannerBlock
    ...TestimonialBlock
    ...ImgMarkupTwoColBlock
    ...MiniBannerBlock
    ...TimelineBlock
    ...ProductsBlock
    ...ContactBlock
    ...ItemsListBlock
    ...RentalBlock
    ...RentalPricesBlock
  }
`;