import React from "react"
import { graphql } from "gatsby"
import { Flex, Box, Text, Button, Icon } from "@chakra-ui/react"
import Markdown from "../../UI/Markdown"
import CustomLink from "../../UI/CustomLink"
import Wrapper from "../Wrapper"
import { HiOutlineMail } from "react-icons/hi"

const Banner = ({ data }) => {
  return (
    <Wrapper data={data.blockSettings} sx={{overflowX: "hidden"}}>
      <Box
      bg="sand"
      pos="relative"
      sx={{
        "&:before, &:after": {
          content: "''",
          display: "block",
          top: "0",
          bg: "sand",
          zIndex: "1"
        },
        "&:before": {
          pos: "absolute",
          height: "100%",
          width: "210vw",
          left: "50%",
          transform: "translateX(-50%)"
        },
        "&:after": {
          pos: ["relative","relative","absolute"],
          height: ["200px","200px","100%"],
          width: ["100vw","100vw","60vw"],
          ml: [-4,-4,0],
          left: [0,0,"40%"],
          bgImage: data.imageBanner.publicURL,
          bgRepeat: "no-repeat",
          bgSize: "cover",
          bgPosition: "center",
          opacity: ".8"
        }
      }}>
        <Flex
        justify="center"
        direction="column"
        p={16}
        pl="0"
        w={["100%","100%","40%"]}
        pos="relative"
        zIndex="2">
          <Text as="h2" mt="0">{data.title}</Text>
          {data.text && (
            <Markdown>{data.text}</Markdown>
          )}
          <Box
          pos="absolute"
          left={["50%","50%","100%"]}
          top={["100%","100%","50%"]}
          transform={["translate(-50%,-50%)","translate(-50%,-50%)","translateY(-50%)"]}
          ml={[0,0,-6]}>
            <CustomLink href="/contact">
              <Button colorScheme="brand"><Icon as={HiOutlineMail} w={5} h={5} mt="2px" mr={2} />Envoyer un message</Button>
            </CustomLink>
          </Box>
        </Flex>
      </Box>
    </Wrapper>
  );
};

export default Banner;

export const query = graphql`
  fragment BannerBlock on MarkdownRemarkFrontmatterBlockscollection {
    title
    text
    imageBanner {
      publicURL
      childImageSharp {
        gatsbyImageData(
          width: 1000
        )
      }
    }
  }
`;
