import React from "react"
import { graphql } from "gatsby"
import { Box, Text, Flex } from "@chakra-ui/react"
import Wrapper from "../Wrapper"
import Markdown from "../../UI/Markdown"
import CustomImage from "../../UI/CustomImage"

const ImgMarkupTwoCol = ({ data }) => {
  return (
    <Wrapper data={data.blockSettings}>
      {data.title && (
        <Text as="h2" textAlign="center" mt="0">{data.title}</Text>
      )}
      {data.text && (
        <Box textAlign="center"><Markdown>{data.text}</Markdown></Box>
      )}
      <Box>
        {data.rows.map((item, i) => {
          let textWidth = item.textWidth
          return (
            <Flex
              key={i}
              alignItems="center"
              flex="1"
              pt={[16,20,24]}
              flexDirection={
                (item.textPosition === "left" && ["column-reverse", "column-reverse", "row"]) ||
                (item.textPosition === "right" && ["column-reverse", "column-reverse", "row-reverse"])
              }
              sx={{
                "&:first-of-type": {
                  pt: 0
                }
              }}
            >
              <Box
                width={["100%", "100%", textWidth]}
                pl={item.textPosition === "left" ? 0 : [4, 4, 8, 10]}
                pr={item.textPosition === "right" ? 0 : [4, 4, 8, 10]}
              >
                <Box
                  sx={{
                    "& > div > ul:last-child": {
                      mb: 0,
                      "li:last-child": {
                        mb: 0
                      }
                    },
                    "p:last-child": {
                      mb: 0
                    },
                    "h3, h4": {
                      "&:first-of-type": {
                        mt: 0
                      }
                    }
                  }}
                >
                  <Markdown>{item.rowText}</Markdown>
                </Box>
              </Box>
              <Box width="auto" flex="1" variant="gridItem" pb={[8, 8, 0]}>
                <Box maxW="450px" ml="auto" mr="auto">
                  <CustomImage img={item.rowImage} />
                </Box>
              </Box>
            </Flex>
          )
        })}
      </Box>
    </Wrapper>
  );
};

export default ImgMarkupTwoCol;

export const query = graphql`
  fragment ImgMarkupTwoColBlock on MarkdownRemarkFrontmatterBlockscollection {
    title
    text
    rows {
      rowImage {
        publicURL
        childImageSharp {
          gatsbyImageData(
            width: 600
          )
        }
      }
      rowText
      textPosition
      textWidth
    }
  }
`;
