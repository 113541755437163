import React from "react"
import { graphql } from "gatsby"
import { Flex, Box, Text, Button, Icon } from "@chakra-ui/react"
import Markdown from "../../UI/Markdown"
import Wrapper from "../Wrapper"
import { HiOutlineMail, HiOutlinePhone } from "react-icons/hi"

const Contact = ({ data }) => {
  let telLink = "tel:+33"
  let mailTo = "mailto:"
  return (
    <Wrapper data={data.blockSettings}>
      {data.title && (
        <Text as="h2" textAlign="center" mt="0">{data.title}</Text>
      )}
      {data.text && (
        <Box textAlign="center"><Markdown>{data.text}</Markdown></Box>
      )}
      <Flex layerStyle="grid" direction={["column","column","row"]} align="stretch">
        {data.contactItems.email && (
          <Box layerStyle="gridItem" width={["100%","50%"]} mt={10} mx="auto">            
            <Box layerStyle="gridItem" bg="sand" position="relative" p={6} pb={10}>
              {data.contactItems.email.emailText && (
                <Box textAlign="center"><Markdown>{data.contactItems.email.emailText}</Markdown></Box>
              )}
              {data.contactItems.email.emailAddress && (
                <Box
                pos="absolute"
                top="100%"
                left="50%"
                transform="translate(-50%,-50%)">
                  <a href={mailTo + data.contactItems.email.emailAddress}>
                    <Button colorScheme="brand"><Icon as={HiOutlineMail} w={5} h={5} mt="2px" mr={2} />{data.contactItems.email.emailAddress}</Button>
                  </a>
                </Box>
              )}
            </Box>
          </Box>
        )}
        {data.contactItems.tel && (
          <Box layerStyle="gridItem" width={["100%","50%"]} mt={10} mx="auto">
            <Box bg="sand" position="relative" p={6} pb={10}>
              {data.contactItems.tel.telText && (
                <Box textAlign="center"><Markdown>{data.contactItems.tel.telText}</Markdown></Box>
              )}
              {data.contactItems.tel.telNumber && (
                <Box
                pos="absolute"
                top="100%"
                left="50%"
                transform="translate(-50%,-50%)">
                  <a href={telLink + data.contactItems.tel.telNumber.replace(/[- )(]/g, "")}>
                    <Button colorScheme="brand"><Icon as={HiOutlinePhone} w={5} h={5} mt="2px" mr={2} />{data.contactItems.tel.telNumber}</Button>
                  </a>
                </Box>
              )}
            </Box>
          </Box>
        )} 

      </Flex>
    </Wrapper>
  );
};

export default Contact;

export const query = graphql`
  fragment ContactBlock on MarkdownRemarkFrontmatterBlockscollection {
    title
    text
    contactItems {
      email {
        emailAddress
        emailText
      }
      tel {
        telNumber
        telText
      }
    }
  }
`;
