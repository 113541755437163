import React from "react"
import { graphql } from "gatsby"
import { Box } from "@chakra-ui/react"
import Wrapper from "../Wrapper"
import Markdown from "../../UI/Markdown"
import RentalItems from "./RentalItems";

const Rental = ({ data }) => {
  return (
    <Wrapper data={data.blockSettings}>
      {data.text && (
        <Box textAlign="center" mx="auto" maxW="1000px" pb={[16,20,24]}><Markdown>{data.text}</Markdown></Box>
      )}
      <RentalItems />
    </Wrapper>
  );
};

export default Rental;

export const query = graphql`
  fragment RentalBlock on MarkdownRemarkFrontmatterBlockscollection {
    text
  }
`;
